.thanks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f0f4f8;
  font-family: "Helvetica Neue", sans-serif;
  background: linear-gradient(45deg, #c024b6 0%, #3023ae 100%);
}

.image-animation {
  width: 140px;
  margin-bottom: 2rem;
  animation: wave 2s infinite;
}

.message {
  text-align: center;
  color: white;
}

.message h1 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.back-button {
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.back-button:hover {
  background-color: #0056b3;
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
