.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f0f4f8;
  font-family: "Helvetica Neue", sans-serif;
  background: linear-gradient(45deg, #c024b6 0%, #3023ae 100%);
}

.logo {
  width: 140px;
  margin-bottom: 2rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  background: hsl(0, 0%, 100%);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 17rem;
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #f0f4f8;
    font-family: "Helvetica Neue", sans-serif;
  }

  .logo {
    width: 250px;
    margin-bottom: 2rem;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    background: hsl(0, 0%, 100%);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* transform: translateY(-30px); */
    /* margin-bottom: 17rem; */
  }

  .login-form h1 {
    color: #333;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .login-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    font-size: 1rem;
  }

  .login-input:focus {
    outline: none;
    border-color: #495057;
  }

  .login-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  .login-button:hover {
    background-color: #0056b3;
  }

  /* 반응형 디자인을 위한 미디어 쿼리 */
  @media (max-width: 768px) {
    .login-form {
      padding: 1.5rem;
    }

    .login-input,
    .login-button {
      padding: 0.5rem;
    }
  }

  .error-message {
    margin-top: 10px; /* 원하는 간격 설정 */
    color: red; /* 원하는 색상 설정 */
  }
}

.login-form h1 {
  color: #333;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.login-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  font-size: 1rem;
}

.login-input:focus {
  outline: none;
  border-color: #495057;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.login-button:hover {
  background-color: #0056b3;
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 768px) {
  .login-form {
    padding: 1.5rem;
  }

  .login-input,
  .login-button {
    padding: 0.5rem;
  }
}

.error-message {
  margin-top: 10px; /* 원하는 간격 설정 */
  color: red; /* 원하는 색상 설정 */
}
