.survey-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* 가로 방향으로 중앙 정렬 */
  justify-content: center; /* 세로 방향으로 중앙 정렬 */
  padding: 2rem; /* 내부 요소와의 여백을 주기 위한 패딩 */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  max-width: 500px;
  margin: 3% auto;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

.survey-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.question {
  margin-bottom: 20px;
}

.question p {
  font-weight: bold;
  color: #444;
}

.question label {
  display: block;
  margin-top: 0.25rem; /* 조정된 상단 여백 */
  padding: 0.375rem 0.75rem; /* padding을 조정하여 레이블의 크기를 변경 */
  background-color: #f8f9fa; /* 배경색 추가 */
  border: 1px solid black; /* 여기에서도 테두리 색상을 검은색으로 설정 */
  border-radius: 0.25rem; /* border-radius를 조정하여 모서리를 둥글게 */
  cursor: pointer;
  transition: background-color 0.15s ease-in-out; /* 부드러운 배경색 변화를 위한 transition 추가 */
}

.question label:hover {
  .question label:hover {
    background-color: #e2e6ea; /* 호버 시 배경색 변경 */
  }
}

.question input[type="radio"] {
  margin-right: 0.5rem; /* 라디오 버튼과 텍스트 사이의 간격 조정 */
  border-color: black; /* 라디오 버튼 테두리 색상을 검은색으로 설정 */
  border: (--bs-border-width) solid #5620b8;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button[type="submit"]:hover {
  background-color: #004494;
}

.spinner-container {
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  height: 100vh; /* 뷰포트의 전체 높이 */
}
