/* LuckyDraw.css */
.lucky-draw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f0f4f8;
  font-family: "Helvetica Neue", sans-serif;
  background: linear-gradient(45deg, #c024b6 0%, #3023ae 100%);
  padding: 2rem;
}

.logo {
  width: 140px;
  margin-bottom: 2rem;
}

.lucky-draw-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: hsl(0, 0%, 100%);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.lucky-draw-title {
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.lucky-draw-cell {
  min-height: 100px; /* 높이를 최소 높이로 설정 */
  padding: 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
  text-align: center;
}

.lucky-draw-button {
  font-size: 1.5rem;
  width: 20%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background-color: #8a2be2;
  color: white;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.lucky-draw-button:hover {
  background-color: #6a1b9a;
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 768px) {
  .lucky-draw-table {
    padding: 1.5rem;
    width: auto; /* 테이블의 너비를 자동으로 설정 */
  }

  .lucky-draw-cell,
  .lucky-draw-button {
    padding: 0.5rem;
    display: block; /* td 요소를 블록 요소로 설정 */
  }
}

.winner-message {
  font-size: 1.5rem;
  color: white;
}
